@font-face {
  font-family: 'vaud-book';
  src: url('./fonts/vaudbook-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'vaud-bold';
  src: url('./fonts/vaudbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal; }
