@import './variables.sass';
@import './mixins.sass';

body {
  text-rendering: geometricPrecision;
  font-family: $main-font;
  line-height: $line-height;
  color: $text-color; }

h1, h2, h3, h4, h5, h6, strong, dt {
  font-family: $bold-font; }
p {
  padding-bottom: $y-text-spacing; }
em {
  font-style: italic; }

code {
  display: inline-block;
  font-size: 0.8em;
  line-height: 1.5;
  padding: 0 0.25em;
  color: rgba($text-color, 0.9);
  background: rgba(#f2f2f2, 0.5);
  border: 1px solid rgba(black, 0.1);
  border-radius: 3px; }

.text-link {
  position: relative;
  display: inline-block;
  color: $pink;
  text-decoration: none;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    bottom: 0.25em;
    left: 0.05em;
    right: 0.03em;
    height: 0;
    border-bottom: 0.08em solid; } }

.strike {
  text-decoration: line-through; }

.subtle {
  @include subtle(); }

.title {
  & > * {
    display: inline-block;
    vertical-align: baseline; } }

@media(max-width: $breakpoint) {
  .title {
    padding-bottom: 0.5em;
    & > * {
      display: block; }
    .subtle {
      padding-left: 0 !important; } } }
