@import './variables.sass';

// Utility ------

@mixin clear() {
  &:after {
    display: table;
    content: " ";
    clear: both; } }

@mixin fill($padding: 0) {
  position: absolute;
  top: $padding;
  right: $padding;
  bottom: $padding;
  left: $padding;
  margin: auto; }

@mixin replace($dir: left) {
  text-indent: if($dir == right, 400vw, -400vw);
  overflow: hidden; }

@mixin vertical-centre() {
  text-align: center;
  font-size: 0;
  white-space: nowrap;
  &:before, & > * {
    display: inline-block;
    vertical-align: middle; }
  &:before {
    content: '';
    width: 0;
    height: 100%; }
  & > * {
    font-size: 1rem;
    white-space: normal; } }

// Shared styling ------

@mixin subtle($rootEm: false) {
  $val: 0.9;
  font: {
    size: if($rootEm, $val + rem, $val + em);
    style: italic; }
  color: rgba($text-color, 0.6); }
