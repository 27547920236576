@import './assets/index.sass';

// ----------------
// structure ------
// ----------------

$sidebar-width: 15vw;
$container-spacing: 3vw;
$content-width: 50vw;
$sidebar-left: (100 - ($sidebar-width + $container-spacing + $content-width)) / 2;
$content-left: $sidebar-left + $sidebar-width + $container-spacing;

$sidebar-offset-mobile: 30vw;
$content-width-mobile: 100 - ($x-outer-spacing * 2);
$car-race-height: 30vw;
$car-race-height-mobile: 35vw;

.nav {
  nav {
    position: fixed;
    top: $y-top-spacing;
    left: $sidebar-left;
    width: $sidebar-width;
    padding-top: 0.1em; }
  header {
    width: $content-width;
    margin-top: $y-top-spacing;
    margin-left: $content-left; } }

.hamburger {
  display: none; }

.container {
  width: $content-width;
  margin-left: $content-left;
  padding-bottom: $y-btm-spacing; }

section {
  padding: $y-section-spacing 0;
  h3 {
    padding-top: $y-text-spacing / 2; }
  .title .subtle {
    padding-left: $x-text-spacing; } }

.car-race {
  height: $car-race-height; }


@media(max-width: $breakpoint) {
  .nav {
    @include fill();
    position: fixed;
    width: auto;
    z-index: 1;
    padding: $y-spacing $x-outer-spacing;
    h1 {
      margin: 0; }
    nav {
      top: 0;
      bottom: 0;
      left: 0;
      width: 100 - $sidebar-offset-mobile; }
    ul {
      padding: ($y-top-spacing + $y-spacing) $x-outer-spacing; } }
  .container {
    width: $content-width-mobile;
    margin-left: $x-outer-spacing;
    padding-bottom: 0; }
  section {
    padding: ($y-section-spacing * 0.5) 0; }
  .intro {
    padding-top: 13vh; }
  .boxout {
    width: 100vw;
    margin-left: -$x-outer-spacing; }
  .car-race {
    height: $car-race-height-mobile; } }

// ------------
// theme ------
// ------------

// purple ------

.purple {
  .text-link {
    color: $purple;
    &:after {
      border-color: $purple; } } }

// red ------

.red {
  .text-link {
    color: $red;
    &:after {
      border-color: $red; } } }

// blue ------

.blue {
  .text-link {
    color: $blue;
    &:after {
      border-color: $blue; } } }

// green ------

.green {
  .text-link {
    color: $green;
    &:after {
      border-color: $green; } } }

// orange ------

.orange {
  .text-link {
    color: $orange;
    &:after {
      border-color: $orange; } } }

// pink ------

.pink {
  .text-link {
    color: $pink;
    &:after {
      border-color: $pink; } } }

// turquoise ------

.turquoise {
  .text-link {
    color: $turquoise;
    &:after {
      border-color: $turquoise; } } }
