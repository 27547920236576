
// Structure
$y-top-spacing: 8vh;
$y-btm-spacing: 20vh;
$x-outer-spacing: 5vw;
$x-spacing: 2vw;
$y-spacing: 3vh;
$x-text-spacing: 0.5em;
$y-text-spacing: 2em;
$y-section-spacing: 8vh;
$breakpoint: 768px;

// Typography
$main-font: vaud-book, sans-serif;
$bold-font: vaud-bold, sans-serif;
$font-size: 20px;
$line-height: 1.85;

// Btns
$btn-diameter: 4vw;

// Theme
$background-color: white;
$text-color: #444;
$box-shadow: 0 1px 4px rgba(black, 0.4);

$blue: #367ddc;
$pink: #d64a92;
$green: #2ed700;
$red: #e93527;
$orange: #e97e27;
$purple: #ab27e9;
$turquoise: #4cbce5;
