@import './variables.sass';

* {
  padding: 0;
  border: 0;
  margin: 0;
  list-style: none; }

// Responsive root em
html {
  $base-width: 1268;
  $min-width: 768;
  $max-width: 3000;
  $start: floor($min-width * 100 / $base-width);
  $end: ceil($max-width * 100 / $base-width);
  @for $i from $start through $end {
    @media(min-width: round($i * ($base-width / 100) * 1px)) {
      font-size: $font-size * ($i / 100); } } }

body {
  background: $background-color; }

#root {
  overflow-x: hidden; }
