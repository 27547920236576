@import './variables.sass';
@import './mixins.sass';

dl {
  $title-width: 33%;
  @include clear();
  dt, dd {
    float: left;
    width: 100%;
    box-sizing: border-box;
    padding: 0.7em 0 0.5em;
    border-top: 1px solid #ddd; }
  dt {
    width: $title-width;
    clear: both; }
  dd {
    width: 100 - $title-width; }
  @media (max-width: $breakpoint) {
    dt, dd {
      width: 100%; }
    dt {
      padding-bottom: 0; }
    dt:first-of-type, dd {
      border-top: 0;
      padding-top: 0; } }
  @media (min-width: $breakpoint + 1) {
    & > *:first-of-type {
      border-top: 0; } } }
