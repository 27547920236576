@import './variables.sass';
@import './mixins.sass';

@mixin baseBtn($icon) {
  display: inline-block;
  font-family: $bold-font;
  line-height: $btn-diameter * 0.5;
  color: $blue;
  text-align: center;
  cursor: pointer; }

@mixin btn($content: null) {
  @include baseBtn($content);
  width: $btn-diameter;
  height: $btn-diameter;
  min-width: 30px;
  min-height: 30px;
  background: $background-color;
  box-shadow: $box-shadow;
  border-radius: 50%;
  .icon {
    transform: scale(0.6); } }

@mixin btn2($content: null) {
  @include baseBtn($content);
  width: $btn-diameter;
  min-width: 30px;
  height: 1.5em;
  background: $text-color;
  border-radius: 0.5vw;
  color: white;
  .icon {
    transform: scale(0.4) translateY(-50%); } }
